export function HamburgerMenuButton(props: {
  menuOpen: boolean;
  setMenuOpen: (value: boolean) => void;
}) {
  const { menuOpen, setMenuOpen } = props;

  return (
    <button
      onClick={() => setMenuOpen(!menuOpen)}
      className="group relative flex flex-col items-center justify-center lg:hidden"
    >
      <div
        id="lines-wrapper"
        style={{
          transform: menuOpen
            ? "translate(0.4rem, -0.1rem)"
            : "translate(0%, 0%)",
        }}
        className=" relative flex flex-col items-center justify-center  transition-all duration-300 ease-in-out"
      >
        <div
          style={{
            transform: menuOpen
              ? "rotate(45deg) translate(0.199rem, 0.90rem)"
              : "rotate(0deg)",
          }}
          className="my-1 h-[0.15rem] w-9 translate-x-3 rounded-[1px] bg-white transition-all duration-300 ease-in-out"
        />
        <div
          style={{ opacity: menuOpen ? "0" : "100" }}
          className="my-1 h-[0.15rem] w-9 rounded-[1px] bg-white transition-all duration-300 ease-in-out"
        />
        <div
          style={{
            transform: menuOpen
              ? "rotate(-45deg) translate(0%, -0.75rem)"
              : "rotate(0deg)",
          }}
          className="my-1 h-[0.15rem] w-9 rounded-[1px] bg-white transition-all duration-300 ease-in-out"
        />
      </div>
    </button>
  );
}
