"use client";

import { useEffect, useState } from "react";
import { getDevLoginUsers } from "./actions";

const LOCAL_STORAGE_DEV_KEY = "_VANIR_DEV_LOGIN_";

export function DevLoginForm() {
  const [availableLogins, setAvailableLogins] = useState<
    { id: string; name: string }[] | undefined
  >();
  const [chosen, setChosen] = useState<string | undefined>();

  useEffect(() => {
    getDevLoginUsers().then((users) => {
      if (!users) return;
      setAvailableLogins(users);
      if (users[0]) setChosen(users[0].id);
    });
  }, []);

  const link = `/api/create-session/${chosen}`;

  useEffect(() => {
    const item = localStorage?.getItem(LOCAL_STORAGE_DEV_KEY) ?? "";
    if (item) {
      if (availableLogins?.find((user) => user.id === item)) {
        setChosen(item);
      } else {
        localStorage.removeItem(LOCAL_STORAGE_DEV_KEY);
      }
    }
  });

  if (!availableLogins) return <div></div>;

  return (
    <div className="absolute right-0 top-0 flex flex-col gap-2 bg-danger text-center text-white">
      <select
        className="bg-transparent p-4 text-center text-2xl"
        name="Dev accounts"
        onChange={(e) => {
          setChosen(e.target.value);
          globalThis?.localStorage?.setItem(
            LOCAL_STORAGE_DEV_KEY,
            e.target.value
          );
        }}
      >
        {availableLogins.map((login, index) => (
          <option key={login.id} value={login.id}>
            {login.name}
          </option>
        ))}
      </select>
      <a className=" pr-4 text-xl font-bold uppercase" href={link}>
        Dev login
      </a>
    </div>
  );
}
