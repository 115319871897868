import { PropsWithChildren } from "react";

export function K33ButtonRed(props: { href?: string } & PropsWithChildren) {
  return (
    <a href={props.href}>
      <button
        className="flex h-[3.375rem] rounded-[4px] text-center items-center bg-[#a54242] py-3 px-10 font-manrope font-semibold text-white transition-colors duration-300 ease-in-out 
          hover:bg-[#803333] focus:bg-[#803333]"
      >
        {props.children}
      </button>
    </a>
  );
}
