import { Component, ErrorInfo, PropsWithChildren } from "react";

interface ErrourBoundaryProps extends PropsWithChildren {
  error: any | null;
}
interface ErrorBoundaryState {
  error: any | null;
}

export class ErrorBoundary extends Component<
  ErrourBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrourBoundaryProps) {
    super(props);

    this.state = { error: null };
  }

  static getDerivedStateFromError(error: any): ErrorBoundaryState {
    return {
      error,
    };
  }

  componentDidCatch(error: any, errorInfo: ErrorInfo) {
    console.error("componentDidCatch:", error, errorInfo); //TODO: proper logging
  }

  render() {
    if (this.state.error) {
      return <p>There was an error 😿</p>;
    }

    return this.props.children;
  }
}
