import { createContext, PropsWithChildren, useContext, useState } from "react";
import { ErrorBoundary } from "src/errors/ErrorBoundary";

type ErrorContext = (error: any) => void;

const errorContext = createContext<ErrorContext>((_: any) => {});

const ErrorContextProvider = errorContext.Provider;

export function useErrorContext() {
  return useContext(errorContext);
}

export function ErrorContext(props: PropsWithChildren) {
  const [error, setError] = useState<any | null>(null);

  return (
    <ErrorBoundary error={error}>
      <ErrorContextProvider value={setError}>
        {props.children}
      </ErrorContextProvider>
    </ErrorBoundary>
  );
}
